import React, { useState, useEffect } from "react"
import Layout from "../retech/components/layout/Layout"
import { Card, Button, Form, InputGroup } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import CategoryService from "../retech/components/Service/servicecategory"
import { Helmet } from "react-helmet"
import Recaptcha from "react-google-recaptcha"
import { navigate } from "gatsby"

export default function TollPage({ data }) {
  const url = typeof window !== "undefined" ? window.location.href : ""
  const urlimage = typeof window !== "undefined" ? window.location.hostname : ""
  const form =
    typeof window !== "undefined" ? window.location.href.split("/")[4] : ""

  const [buttonDisabled, setButtonDisabled] = React.useState(true)
  const [isLoading, setLoading] = React.useState(false)

  useEffect(() => {
    setFormState({
      ...formState,
      source: document.querySelector(".source")?.innerText,
    })
  }, [])

  const [formState, setFormState] = useState({
    source: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    priority: "",
    message: "",
  })
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = e => {
    let x = document.getElementById("firstname").value
    let n = document.getElementById("message").value
    let y = document.getElementById("lastname").value
    let z = document.getElementById("phone").value
    let f = document.getElementById("email").value
    let r = document.getElementById("priority").value

    if (x == "" || n == "" || y == "" || z == "" || f == "" || r == "") {
      e.preventDefault()
      return false
    }
    setLoading(true)
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "partner",
        "g-recaptcha-response": recaptchaValue,
        ...formState,
      }),
    })
      .then(() => {
        navigate(form.getAttribute("action"))
        setLoading(true)
      })
      .catch(error => alert(error))

    e.preventDefault()
  }
  const handleChange1 = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit1 = e => {
    let x = document.getElementById("firstname").value
    let n = document.getElementById("message").value
    let y = document.getElementById("lastname").value
    let z = document.getElementById("phone").value
    let f = document.getElementById("email").value
    let r = document.getElementById("priority").value

    if (x == "" || n == "" || y == "" || z == "" || f == "" || r == "") {
      e.preventDefault()
      return false
    }
    setLoading(true)
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "message",
        "g-recaptcha-response": recaptchaValue,
        ...formState,
      }),
    })
      .then(() => {
        navigate(form.getAttribute("action"))
        setLoading(true)
      })
      .catch(error => alert(error))

    e.preventDefault()
  }

  const recaptchaRef = React.createRef()
  const {
    ServiceItemTitle,
    ServiceItemPortfolioTitle,
    ServiceItemDetailImage,
    ServiceItemDetailTitle,
    ServiceItemDetailTitleTwo,
    ServiceItemDetailDescriptionTwo,
    Title,
    TitleTwo,
    LastDescription,
    Items_Images,
    video,
    ServiceItemDetailDescription,
    ServiceItem1DetailTitle,
    ServiceItemDetail1Description,
    WidgetDetail1Description,
    WidgetDetailTitle,
    RelatedPortfolio,
    AfterSalesTitle,
    AfterSalesImage,
    AfterSalesServiceDescription,
    AfterSalesDescription,
    AfterSalesDescriptionTwo,
    AfterSalesTitleTwo,
    AfterSalesSmallTitle,
    PartsDescription,
    PartsImage,
    PartsTitle,
    BgImage,
    EquipmentDescription,
    EquipmentDescriptionFirst,
    EquipmentDescriptionSecond,
    EquipmentTitle,
    Equipment_Items,
    AfterSalesServiceItem,
  } = data.serviceItemJson
  var callback = function () {
    console.log("Done!!!!")
  }

  // specifying verify callback function
  var verifyCallback = function (response) {
    console.log(response)
  }
  return (
    <Layout>
      <Helmet>
        <title>Retech | {ServiceItemTitle}</title>

        <meta name="description" content={ServiceItemDetailDescription} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={ServiceItemTitle} />
        <meta
          property="og:description"
          content={ServiceItemDetailDescription}
        />
        <meta property="og:image" content={urlimage + "/logo.svg"} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={ServiceItemTitle} />
        <meta
          property="twitter:description"
          content={ServiceItemDetailDescription}
        />
        <meta property="twitter:image" content={urlimage + "/logo.svg"} />
      </Helmet>
      <div className="container-fluid px-0 mb-5">
        <div
          className="show_bg_desktop d-none d-sm-block"
          style={{
            backgroundImage:
              "linear-gradient(349.66deg,#1f2025 4.11%,rgba(31,32,37,.3) 18.52%,rgba(31,32,37,0) 36.03%,rgba(31,32,37,0) 70.01%),linear-gradient(86deg,#000 4.11%,transparent 70.01%),url(" +
              ServiceItemDetailImage +
              ")",
            height: "391px",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-5 pt-5 mt-5 pr-5">
                <h1 className="font-wight-boder pb-5 source">
                  {ServiceItemTitle}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div
          className="show_bg_desktop d-block d-sm-none"
          style={{
            backgroundImage:
              "linear-gradient(349.66deg,#1f2025 4.11%,rgba(31,32,37,.3) 18.52%,rgba(31,32,37,0) 36.03%,rgba(31,32,37,0) 70.01%),linear-gradient(86deg,#000 4.11%,transparent 70.01%),url(" +
              ServiceItemDetailImage +
              ")",
            backgroundSize: "cover",
            height: "300px",
          }}
        >
          <div className="container pt-5">
            <div className="row">
              <div className="col-md-5">
                <h3 className="font-wight-bold ">{ServiceItemTitle}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container faq">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 d-none d-sm-block">
              <small>
                <Link className="return-link" to="/service">
                  <img
                    src="/img/Vector.svg"
                    alt="arrow return"
                    className="mr-4"
                  />
                  Services home
                </Link>
              </small>
              <hr></hr>
              <h6 className="font-weight-bold">Services</h6>

              <CategoryService />
            </div>
            {(form === "after-sales" ||
              form === "toll-melting" ||
              form === "research-development") && (
              <>
                <div className="col-md-7 mb-5 py-5">
                  {video && (
                    <img src={video} className="w-100 my-3" alt="video" />
                  )}
                  <h6 className="font-weight-bolder mb-3">
                    {ServiceItemDetailTitle}
                  </h6>
                  <p className="mb-4">{ServiceItemDetailDescription}</p>
                  <h6 className="font-weight-bolder mb-3">
                    {ServiceItemDetailTitleTwo}
                  </h6>
                  <p className="mb-4">{ServiceItemDetailDescriptionTwo}</p>
                  {Title && (
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#0b559b",
                        clipPath: "polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%)",
                        textAlign: "center",
                      }}
                    >
                      <p className="text-white font-weight-bold p-2 px-1">
                        {Title}
                      </p>
                    </div>
                  )}
                  <div className="row">
                    {Items_Images?.map((node, index) => {
                      return (
                        <div
                          className="col-md-4 mb-4 px-0"
                          key={`${node.Title}-${index + Math.random()}`}
                        >
                          <Card className="border-0 text-center bg-transparent">
                            <Card.Body
                              className="p-1"
                              style={{ cursor: "pointer" }}
                            >
                              <Link to={node.Link}>
                                <img
                                  src={node.Image}
                                  alt="serction E"
                                  style={{
                                    maxWidth: "80%",
                                  }}
                                />
                                <p className="mt-4 filter-name">{node.Title}</p>
                              </Link>
                            </Card.Body>
                          </Card>
                        </div>
                      )
                    })}
                  </div>
                  {TitleTwo && (
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#0b559b",
                        clipPath: "polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%)",
                        textAlign: "center",
                      }}
                    >
                      <p className="text-white font-weight-bold p-2 px-1">
                        {TitleTwo}
                      </p>
                    </div>
                  )}
                  <p className="mb-4">{LastDescription}</p>
                </div>

                {BgImage && (
                  <img
                    src={BgImage}
                    className="w-100 bg-services-abstract"
                    alt="background"
                    style={{
                      position: "absolute",
                      /* right: -40%; */
                      objectFit: "cover",
                      transform: "translate(138px, 222px)",
                      zIndex: "-1",
                    }}
                  />
                )}
              </>
            )}

            {PartsTitle && (
              <>
                <div className="col-md-7 mb-5 py-5">
                  <h4 className="font-weight-bolder mb-3">{PartsTitle}</h4>
                  <div className="row">
                    <div className="col-md-7">
                      <p className="mb-4">{PartsDescription}</p>
                    </div>
                    <div className="col-md-5   background-des" key="1">
                      <img
                        src={PartsImage}
                        className="w-100"
                        style={{ height: "90%" }}
                        alt="background"
                      />
                    </div>
                  </div>
                </div>
                {BgImage && (
                  <img
                    src={BgImage}
                    className="w-100 bg-services-abstract"
                    alt="background"
                    style={{
                      height: "100%",
                      position: "absolute",
                      /* right: -40%; */
                      objectFit: "cover",
                      transform: "translate(138px, 222px)",
                      zIndex: "-1",
                    }}
                  />
                )}
              </>
            )}

            {EquipmentTitle && (
              <>
                <div className="col-md-7 mb-5 py-5">
                  <div className="row">
                    <div className="col-md-12 ">
                      <h4 className="font-weight-bolder mb-3">
                        {EquipmentTitle}
                      </h4>
                      <p className="mb-2">{EquipmentDescriptionFirst}</p>
                      <p className="mb-2">{EquipmentDescriptionSecond}</p>
                      <p className="mb-2">{EquipmentDescription}</p>
                    </div>
                  </div>
                  <div className="row">
                    {Equipment_Items?.map((node, index) => {
                      return (
                        <div
                          className="col-md-3 mb-4 px-0"
                          key={`${node.Title}-${index + Math.random()}`}
                        >
                          <Card className="border-0 text-center bg-transparent">
                            <Card.Header className="p-1 bg-transparent border-0">
                              <p className="mt-4 font-weight-bolder px-1 ">
                                {node.Title}
                              </p>
                            </Card.Header>
                            <Card.Body className="p-1 bg-transparent">
                              <img
                                src={node.Image}
                                alt="serction E"
                                style={{
                                  maxWidth: "100%",
                                }}
                              />
                            </Card.Body>
                          </Card>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {BgImage && (
                  <img
                    src={BgImage}
                    className="w-100 bg-services-abstract"
                    alt="background"
                    style={{
                      height: "100%",
                      position: "absolute",
                      /* right: -40%; */
                      objectFit: "cover",
                      transform: "translate(184px, 106px)",
                      zIndex: "-1",
                    }}
                  />
                )}
              </>
            )}

            {AfterSalesTitle && (
              <>
                <div className="col-md-7 mb-5 py-5">
                  <h4 className="font-weight-bolder mb-3">{AfterSalesTitle}</h4>
                  <div className="row">
                    <div className="col-md-7">
                      <p className="">{AfterSalesDescription}</p>
                    </div>
                    <div className="col-md-5 background-des" key="1">
                      <img
                        src={AfterSalesImage}
                        className="w-100"
                        style={{ height: "90%" }}
                        alt="background"
                      />
                    </div>
                  </div>

                  <p className="">{AfterSalesDescriptionTwo}</p>
                  <p className="font-weight-bolder mb-3">
                    {AfterSalesTitleTwo}
                  </p>
                  <p className="font-weight-bolder my-3">
                    {AfterSalesSmallTitle}
                  </p>
                  <div>
                    <ul>
                      {AfterSalesServiceItem?.map((node, index) => {
                        return (
                          <li
                            key={`${node.Title}-${index + Math.random()}`}
                            className="mb-2"
                          >
                            {node.Title}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                {BgImage && (
                  <img
                    src={BgImage}
                    className="w-100 bg-services-abstract"
                    alt="background"
                    style={{
                      height: "100%",
                      position: "absolute",
                      /* right: -40%; */
                      objectFit: "cover",
                      transform: "translate(138px, 70px)",
                      zIndex: "-1",
                    }}
                  />
                )}
              </>
            )}
          </div>
          {ServiceItem1DetailTitle && (
            <div className="row mt-5">
              <div className="col-md-6">
                <h3 className="font-weight-bolder mb-3">
                  {ServiceItem1DetailTitle}
                </h3>
                <p className="mb-4">{ServiceItemDetail1Description}</p>
              </div>
              {WidgetDetailTitle && (
                <div className="col-md-6">
                  <h3 className="font-weight-bolder mb-3">
                    {WidgetDetailTitle}
                  </h3>
                  <p className="mb-4">{WidgetDetail1Description}</p>
                </div>
              )}
            </div>
          )}
        </div>
        {RelatedPortfolio && (
          <div className="col-md-12 ">
            <h4 className="font-weight-bold mb-4">
              {ServiceItemPortfolioTitle}
              <img src="/img/Next.svg" alt="down-arrow" className="ml-3" />
            </h4>
          </div>
        )}
        {/* {AfterSalesTitle && (
          <div clasName="col-md-12 ">
            <h4 className="font-weight-bold mb-4">
              After-Sales & Customer Service Support
              <img src="/img/Next.svg" alt="down-arrow" className="ml-3" />
            </h4>
          </div>
        )} */}
      </div>

      {RelatedPortfolio && (
        <>
          {RelatedPortfolio?.map((node, index) => {
            return (
              <div
                className="container-fluid alternative-bg py-5 px-0"
                key={`${node.Title}-${index + Math.random()}`}
              >
                <div className="container">
                  <div className="col-md-12 ">
                    <div className="row">
                      <div className="col-md-6">
                        <h5 className="font-weight-bolder mb-3">
                          {node.Title}
                        </h5>
                        <p className="mb-4 pr-4">{node.Description}</p>
                      </div>
                      <div className="col-md-6 pt-2  border-left border-color pl-5 ">
                        <div className="row">
                          <div className="col-md-7 mb-5">
                            <h6 className="font-weight-bold">Materials:</h6>
                            <p className="mb-4 text-dark">
                              <small> {node.Materials}</small>
                            </p>
                            <a
                              className="font-weight-bold download-link"
                              href={node.file}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="/img/iconfinder_download.svg"
                                alt="download"
                                className="mr-3"
                              />{" "}
                              {node.ApplicationFileTextName}
                            </a>
                          </div>
                          <div className="col-md-5">
                            <h6 className="font-weight-bold">Applications:</h6>
                            <p className="mb-4 text-dark">
                              <small> {node.Applications}</small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )}

      {form === "after-sales" ? (
        <div className="container form-product">
          <div className="col-md-12 px-0 py-5">
            <Card className="product color-retech-card">
              <h4 className="text-white font-weight-bolder mb-3">
                Contact Retech’s Customer Service Team{" "}
              </h4>
              <p className="text-white">
                <small>
                  Fill up the form our team will get back to you within 24 Hours
                </small>
              </p>
              <form
                onSubmit={handleSubmit}
                name="partner"
                method="post"
                data-netlify="true"
                data-netlify-recaptcha="true"
                data-netlify-honeypot="bot-field"
                action="/thank-you"
              >
                <input type="hidden" name="form-name" value="partner" />

                <Form.Row className="mb-4">
                  <div className="col-md-3 mb-4">
                    <Form.Control
                      className="py-4"
                      type="text"
                      id="source"
                      name="source"
                      onChange={handleChange}
                      value={formState.source}
                      style={{ display: "none" }}
                    />
                    <Form.Label className="font-wight-bolder text-white">
                      First Name*
                    </Form.Label>
                    <Form.Control
                      className="py-4"
                      placeholder="First Name"
                      id="firstname"
                      type="text"
                      name="firstname"
                      onChange={handleChange}
                      value={formState.firstname}
                    />
                  </div>
                  <div className="col-md-3 mb-4">
                    <Form.Label className="font-wight-bolder text-white">
                      Last Name*
                    </Form.Label>
                    <Form.Control
                      className="py-4"
                      placeholder="Enter your last name"
                      id="lastname"
                      type="text"
                      name="lastname"
                      onChange={handleChange}
                      value={formState.lastname}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <Form.Label className="font-wight-bolder text-white">
                      Phone*
                    </Form.Label>
                    <Form.Control
                      className="py-4"
                      type="tel"
                      placeholder="Enter your phone"
                      id="phone"
                      name="phone"
                      onChange={handleChange}
                      value={formState.phone}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <Form.Label className="font-wight-bolder text-white">
                      Email*
                    </Form.Label>
                    <Form.Control
                      className="py-4"
                      type="email"
                      placeholder="Enter your email address"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={formState.email}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <Form.Group>
                      <Form.Label className="font-wight-bolder text-white">
                        Priority*
                      </Form.Label>
                      <Form.Control
                        as="select"
                        size="lg"
                        type="text"
                        placeholder="Enter your Priority"
                        id="priority"
                        name="priority"
                        onChange={handleChange}
                        value={formState.priority}
                      >
                        <option value="">CHOOSE *</option>

                        <option value="Sales Domestic US">
                          Sales - Domestic US
                        </option>
                        <option value="Sales Non US">Sales - Non US</option>
                        <option value="Information Request">
                          Information Request
                        </option>
                        <option value="Research & Development">
                          Research & Development
                        </option>
                        <option value="Service/Aftermarket Support">
                          Service/Aftermarket Support
                        </option>
                        <option value="Other"> Other</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Label className="font-wight-bolder text-white">
                      Message*
                    </Form.Label>
                    <Form.Control
                      className="py-4"
                      as="textarea"
                      placeholder="Write you message"
                      rows={3}
                      id="message"
                      name="message"
                      onChange={handleChange}
                      value={formState.message}
                    />
                  </div>
                </Form.Row>

                <Recaptcha
                  ref={recaptchaRef}
                  sitekey="6LdwPXYbAAAAAMgj5Nqj76lv39oKQB5Jtj48_9N9"
                  size="normal"
                  id="recaptcha-google"
                  onChange={() => setButtonDisabled(false)}
                />
                <InputGroup className="my-3">
                  <InputGroup.Append>
                    {!isLoading && (
                      <Button
                        type="submit"
                        variant="outline-secondary"
                        className="text-white border-white p-3"
                        disabled={buttonDisabled}
                      >
                        Send Message
                      </Button>
                    )}
                    {isLoading && (
                      <Button
                        type="submit"
                        variant="outline-secondary"
                        className="text-white border-white p-3"
                        disabled={buttonDisabled}
                      >
                        <i className="fa fa-spinner fa-spin"></i>
                        Sending...
                      </Button>
                    )}
                  </InputGroup.Append>
                </InputGroup>
              </form>{" "}
            </Card>
          </div>
        </div>
      ) : (
        <div className="container form-product">
          <div className="col-md-12 px-0 py-5">
            <Card className="product color-retech-card">
              <h4 className="text-white font-weight-bolder mb-3">
                Make Contact with Retech{" "}
              </h4>
              <p className="text-white">
                <small>
                  Fill up the form our team will get back to you within 24 Hours
                </small>
              </p>
              <form
                onSubmit={handleSubmit1}
                name="message"
                method="post"
                data-netlify="true"
                data-netlify-recaptcha="true"
                data-netlify-honeypot="bot-field"
                action="/thank-you"
              >
                <input type="hidden" name="form-name" value="message" />

                <Form.Row className="mb-4">
                  <div className="col-md-3 mb-4">
                    <Form.Control
                      className="py-4"
                      type="text"
                      id="source"
                      name="source"
                      onChange={handleChange1}
                      value={formState.source}
                      style={{ display: "none" }}
                    />
                    <Form.Label className="font-wight-bolder text-white">
                      First Name*
                    </Form.Label>
                    <Form.Control
                      className="py-4"
                      placeholder="First Name"
                      id="firstname"
                      type="text"
                      name="firstname"
                      onChange={handleChange1}
                      value={formState.firstname}
                    />
                  </div>
                  <div className="col-md-3 mb-4">
                    <Form.Label className="font-wight-bolder text-white">
                      Last Name*
                    </Form.Label>
                    <Form.Control
                      className="py-4"
                      placeholder="Enter your last name"
                      id="lastname"
                      type="text"
                      name="lastname"
                      onChange={handleChange1}
                      value={formState.lastname}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <Form.Label className="font-wight-bolder text-white">
                      Phone*
                    </Form.Label>
                    <Form.Control
                      className="py-4"
                      type="tel"
                      placeholder="Enter your phone"
                      id="phone"
                      name="phone"
                      onChange={handleChange1}
                      value={formState.phone}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <Form.Label className="font-wight-bolder text-white">
                      Email*
                    </Form.Label>
                    <Form.Control
                      className="py-4"
                      type="email"
                      placeholder="Enter your email address"
                      id="email"
                      name="email"
                      onChange={handleChange1}
                      value={formState.email}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <Form.Group>
                      <Form.Label className="font-wight-bolder text-white">
                        Priority*
                      </Form.Label>
                      <Form.Control
                        as="select"
                        size="lg"
                        type="text"
                        placeholder="Enter your Priority"
                        id="priority"
                        name="priority"
                        onChange={handleChange1}
                        value={formState.priority}
                      >
                        <option value="">CHOOSE *</option>

                        <option value="Sales Domestic US">
                          Sales - Domestic US
                        </option>
                        <option value="Sales Non US">Sales - Non US</option>
                        <option value="Information Request">
                          Information Request
                        </option>
                        <option value="Research & Development">
                          Research & Development
                        </option>
                        <option value="Service/Aftermarket Support">
                          Service/Aftermarket Support
                        </option>
                        <option value="Other"> Other</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Label className="font-wight-bolder text-white">
                      Message*
                    </Form.Label>
                    <Form.Control
                      className="py-4"
                      as="textarea"
                      placeholder="Write you message"
                      rows={3}
                      id="message"
                      name="message"
                      onChange={handleChange1}
                      value={formState.message}
                    />
                  </div>
                </Form.Row>

                <Recaptcha
                  ref={recaptchaRef}
                  sitekey="6LdwPXYbAAAAAMgj5Nqj76lv39oKQB5Jtj48_9N9"
                  size="normal"
                  id="recaptcha-google"
                  onChange={() => setButtonDisabled(false)}
                />
                <InputGroup className="my-3">
                  <InputGroup.Append>
                    {!isLoading && (
                      <Button
                        type="submit"
                        variant="outline-secondary"
                        className="text-white border-white p-3"
                        disabled={buttonDisabled}
                      >
                        Send Message
                      </Button>
                    )}
                    {isLoading && (
                      <Button
                        type="submit"
                        variant="outline-secondary"
                        className="text-white border-white p-3"
                        disabled={buttonDisabled}
                      >
                        <i className="fa fa-spinner fa-spin"></i>
                        Sending...
                      </Button>
                    )}
                  </InputGroup.Append>
                </InputGroup>
              </form>{" "}
            </Card>
          </div>
        </div>
      )}
    </Layout>
  )
}
export const query = graphql`
  query Myquery($ServiceItemCTALink: String) {
    serviceItemJson(ServiceItemCTALink: { eq: $ServiceItemCTALink }) {
      ServiceItemTitle
      ServiceItemPortfolioTitle
      ServiceItemDetailImage
      ServiceItemDetailTitle
      ServiceItemDetailTitleTwo
      ServiceItemDetailDescriptionTwo
      Title
      TitleTwo
      LastDescription
      Items_Images {
        Image
        Title
        Link
      }
      video
      ServiceItemDetailDescription
      ServiceItem1DetailTitle
      ServiceItemDetail1Description
      WidgetDetail1Description
      WidgetDetailTitle
      AfterSalesTitle
      AfterSalesImage
      AfterSalesServiceItem {
        Title
      }

      AfterSalesServiceDescription
      AfterSalesDescription
      AfterSalesDescriptionTwo
      AfterSalesTitleTwo
      AfterSalesSmallTitle
      AfterSalesServicItem
      PartsDescription
      PartsImage
      PartsServices
      PartsTitle
      BgImage
      EquipmentTitle
      Equipment_Items {
        Image
        Title
      }
      EquipmentDescription
      EquipmentDescriptionFirst
      EquipmentDescriptionSecond
      RelatedPortfolio {
        Applications
        Description
        Materials
        Title
        ApplicationFileTextName
        file
      }
    }
  }
`
